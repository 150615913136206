import type { gsap } from 'gsap';

export default (gsap: gsap): () => void => {
  const init = (el: HTMLElement) => {

    gsap.set(el, {
      x: () => -window.innerWidth * 0.1,
    });

    gsap.to(el, {
      x: window.innerWidth * 0.1,
      // ease: 'none',
      scrollTrigger: {
        trigger: el, // should we use another element as trigger?
        start: () => 'top bottom',
        end: () => `bottom top`,
        scrub: true,
        markers: window.location.host === 'x.ddev.site',
        invalidateOnRefresh: true,
      },
    });
  };

  (document.querySelectorAll('.ce-infoboxes .icon-big-arrow') as NodeListOf<HTMLElement>).forEach(init);

  // cleanup function - executes when media not matched
  return () => {
    gsap.set('.ce-infoboxes .icon-big-arrow', {
      x: 0,
    });
  };
};
