import type { gsap } from 'gsap';

const DEFAULT_SPEED = 1.4;

export default (gsap: gsap): () => void => {
  const init = (el: HTMLElement) => {
    const speed = el.dataset.speed ? parseFloat(el.dataset.speed) : DEFAULT_SPEED;

    gsap.set(el, {
      y: () => `${-0.5 * (1 - speed) * 100}vh`, // -0.5 * (1 - speed) * window.innerHeight,
      rotation: -22.5
    });

    gsap.to(el, {
      y: () => 0.5 * (1 - speed) * window.innerHeight,
      rotation: 22.5,
      ease: 'none',
      scrollTrigger: {
        trigger: el, // should we use another element as trigger?
        start: () => 'top bottom',
        end: () => `bottom+=${(1 - speed) * window.innerHeight} top`,
        scrub: true,
        markers: window.location.host === 'x.ddev.site',
        invalidateOnRefresh: true,
      },
    });
  };

  (document.querySelectorAll('.content-icon-wrapper') as NodeListOf<HTMLElement>).forEach(init);

  // cleanup function - executes when media not matched
  return () => {
    gsap.set('.content-icon-wrapper', {
      y: 0,
      rotation: 0,
    });
  };
};
