import type { gsap } from 'gsap';

const DEFAULT_SPEED = 1.3;

export default (gsap: gsap): () => void => {
  const init = (el: HTMLElement) => {
    const speed = el.dataset.speed ? parseFloat(el.dataset.speed) : DEFAULT_SPEED;

    if (el.dataset.isFirst) {
      gsap.to(el, {
        y: () => 0.5 * (1 - speed) * window.innerHeight,
        ease: 'none',
        scrollTrigger: {
          trigger: el, // should we use another element as trigger?
          start: () => 'top top',
          end: () => `bottom+=${0.5 * (1 - speed) * window.innerHeight} top`,
          scrub: true,
          markers: window.location.host === 'x.ddev.site',
          invalidateOnRefresh: true,
        },
      });
    } else {
      gsap.set(el, {
        y: () => `${-0.5 * (1 - speed) * 100}vh`, // -0.5 * (1 - speed) * window.innerHeight,
      });

      gsap.to(el, {
        y: () => 0.5 * (1 - speed) * window.innerHeight,
        ease: 'none',
        scrollTrigger: {
          trigger: el, // should we use another element as trigger?
          start: () => 'top bottom',
          end: () => `bottom+=${(1 - speed) * window.innerHeight} top`,
          scrub: true,
          markers: window.location.host === 'x.ddev.site',
          invalidateOnRefresh: true,
        },
      });
    }
  };

  (document.querySelectorAll('[data-speed]') as NodeListOf<HTMLElement>).forEach(init);

  // cleanup function - executes when media not matched
  return () => {
    gsap.set('[data-speed]', {
      y: 0,
    });
  };
};
