// import * as polyfills from './polyfills';

// Import our custom CSS
import '../scss/styles.scss'
import Alpine from 'alpinejs'

// gsap
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import TextAnimation from './gsap/TextAnimation';
import ContentIconAnimation from './gsap/ContentIconAnimation';
import ArrowsAnimation from './gsap/ArrowsAnimation';
import SpeedAnimation from './gsap/SpeedAnimation';

// components
import PlayVideoPreviews from './components/PlayVideoPreviews';
import Video from './components/Video';
import InfographicsTooltip from './components/InfographicsTooltip';
import PhoneSlider from './components/PhoneSlider';

gsap.registerPlugin(ScrollTrigger);

const DESKTOP_BREAKPOINT = 768;

Alpine.data('PlayVideoPreviews', PlayVideoPreviews);
Alpine.data('Video', Video);
Alpine.data('InfographicsTooltip', InfographicsTooltip);
Alpine.data('PhoneSlider', PhoneSlider);

window.Alpine = Alpine
Alpine.start()

const initGSAP = () => {
  const initSmoothScrollAndGSAP = () => {
    if (window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
      return;
    }

    // this.initSmoothScroll();

    const gsapMM = gsap.matchMedia();
    gsapMM.add(`(min-width: ${DESKTOP_BREAKPOINT}px)`, (context) => {
      const cleanupMethods = [];

      cleanupMethods.push(TextAnimation(gsap));
      cleanupMethods.push(ContentIconAnimation(gsap));
      cleanupMethods.push(ArrowsAnimation(gsap));
      cleanupMethods.push(SpeedAnimation(gsap));

      return () => {
        cleanupMethods.forEach(fn => {
          if (typeof fn === 'function') {
            fn();
          }
        });
      };
    });

    // run on mobiles as well
    // DividerAnimation(gsap);
  };

  initSmoothScrollAndGSAP();
};

initGSAP();
