import {computePosition, offset, shift} from '@floating-ui/dom';

export default () => ({
  isVisible: false,

  init() {
    this.$watch('isVisible', (value) => {
      const infoArrow = (this.$root ?? this.$refs.rootEl).closest('.ce-infographics')?.querySelector('.info-arrow');

      if (value) {
        this.update();

        window.requestAnimationFrame(() => {
          infoArrow?.classList?.add('opacity-0');
        });
      } else {
        infoArrow?.classList?.remove('opacity-0');
      }
    });
  },

  update() {
    computePosition(this.$refs.tooltipBtn, this.$refs.tooltip, {
      placement: 'bottom',
      middleware: [
        offset(window.innerWidth > 992 ? 30 : 16),
        shift({padding: 20}),
      ],
    }).then(({x, y, placement, middlewareData}) => {
      Object.assign(this.$refs.tooltip.style, {
        left: `${x}px`,
        top: `${y}px`,
      });
    });
  },

  toggleTooltip() {
    this.isVisible = !this.isVisible;
  },
});