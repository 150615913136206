import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';

export default () => ({
  init() {
    const lightbox = new PhotoSwipeLightbox({
      gallery: (this.$root ?? this.$refs.rootEl),
      children: 'a',
      paddingFn: (viewportSize, itemData, index) => {
        if (viewportSize.x > 1000) {
          return { top: 40, bottom: 40, left: 40, right: 40 };
        }

        return { top: 20, bottom: 20, left: 20, right: 20 };
      },
      showHideAnimationType: 'none',
      pswpModule: () => import('photoswipe')
    });

    lightbox.addFilter('itemData', (itemData, index) => {
      const googleMapUrl = itemData.element.dataset.googleMapUrl;
      if (googleMapUrl) {
        itemData.googleMapUrl = googleMapUrl;
      }
      return itemData;
    });

    lightbox.on('contentLoad', (e) => {
      const { content } = e;
      if (content.type === 'video') {
        // Prevent the default behavior
        e.preventDefault();

        // Create a container for video
        content.element = document.createElement('div');
        content.element.className = 'pswp__video-container';

        // Create video element
        const video = document.createElement('video');
        video.src = content.data.src;
        video.width = content.data.width ?? 1920;
        video.height = content.data.height ?? 1080;
        video.controls = true;
        video.autoplay = true;
        video.className = 'pswp__video';

        content.element.appendChild(video);
      }
    });

    const pauseVideo = (lightbox) => {
      const videoElements = lightbox.pswp.container.querySelectorAll('video');
      videoElements.forEach(video => {
        video.pause();
      });
    };

    // Clean up video when slide changes or lightbox closes
    lightbox.on('change', () => {
      pauseVideo(lightbox);
    });

    lightbox.on('close', () => {
      pauseVideo(lightbox);
    });

    lightbox.init();
  },
});
