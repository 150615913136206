export default () => ({
  init() {
    setTimeout(() => {
      const videos = (this.$root ?? this.$refs.rootEl).querySelectorAll('video');
      this.playAllVideosInSequence(videos, 0);
    }, 2000);
  },

  playAllVideosInSequence(videos, index) {
    if (index >= videos.length) {
      return;
    }

    const video = videos[index];
    video.addEventListener('play', () => {
      video.closest('.video-poster')?.classList.add('is-playing');
    });
    video.addEventListener('ended', () => {
      video.closest('.video-poster')?.classList.remove('is-playing');
      this.playAllVideosInSequence(videos, index + 1);
    });
    video.play();
  },
});