// import Swiper JS
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
// import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

export default () => ({
  isVisible: false,

  init() {
    const swiper = new Swiper((this.$root ?? this.$refs.rootEl).querySelector('.swiper'), {
      speed: 500,
      spaceBetween: 20,
      threshold: 10,
      watchOverflow: true,
      followFinger: true,
      modules: [Navigation],
      navigation: {
        prevEl: (this.$root ?? this.$refs.rootEl).querySelector('.swiper-button-prev'),
        nextEl: (this.$root ?? this.$refs.rootEl).querySelector('.swiper-button-next'),
      },
    });
  },
});