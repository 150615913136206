import type { gsap } from 'gsap';

const splitText: (element: HTMLElement) => void = (element: HTMLElement): void => {
  // skip nodes that are not Elements or that are scripts/styles
  if (element.nodeType !== Node.ELEMENT_NODE || element instanceof HTMLScriptElement || element instanceof HTMLScriptElement) {
    return;
  }
  // skip already split elements
  if (element.nodeType === Node.ELEMENT_NODE && element.classList.contains('word-fragment')) {
    return;
  }

  [...element.childNodes].forEach((childNode) => {
    if (childNode.nodeType === Node.TEXT_NODE && childNode.data.length > 1) {
      const elementContainer = document.createDocumentFragment();
      (childNode as Text).data
        .split(/(\s+)/)
        .filter(t => t.trim().length)
        .forEach((t, i) => {
          const elem = document.createElement('span');
          elem.className = 'word-fragment';
          elem.innerText = t.trim();

          if (i !== 0) {
            elementContainer.appendChild(document.createTextNode(' '));
          }
          elementContainer.appendChild(elem);
        });

      element.replaceChild(elementContainer, childNode);
    } else {
      splitText(childNode as HTMLElement);
    }
  });
};

export default (gsap: gsap): void => {
  document.querySelectorAll('.text-animation, h1, .h1, h2, .h2').forEach((elem) => {
    splitText(elem as HTMLElement);

    const wf = elem.querySelectorAll('.word-fragment');
    if (!wf.length) {
      return;
    }

    gsap.fromTo(wf, {
      yPercent: -120,
    }, {
      yPercent: 0,
      opacity: 1,
      stagger: 0.15,
      scrollTrigger: {
        start: () => 'top bottom',
        end: () => 'bottom top',
        trigger: elem,
        once: true,
        invalidateOnRefresh: true,
      }
    });
  });
};
